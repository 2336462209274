.eft-esriviewhost {
  height: 100%;
  width: 100%;
  min-height: 100px;
  min-width: 100px;
}
.eft-esriviewhost .esri-view {
  height: 100%;
  width: 100%;
}
