/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html,
body,
#root,
.App {
  width: 100vw;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.navbar {
  height: 6%;
}

.contentContainer {
  height: 94%;
  display: flex;
}

.sidePanelContainer {
  width: 40%;
}

.crosshair {
  position: absolute;
  height: 15%;
  width: 15%;
  border-style: solid;
  border-color: white;
  z-index: 3;
  top: 50%;
  left: 50%;
  pointer-events: none;
  border-radius: 15%;
  transform: translate(-50%, -50%);
}

.fill-parent {
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
}




//navigation table stuff///////////
.navigationHelpTable
{
  text-align: center;
  width: 100%;
}

.navigationHelpTable th
{
  padding-top: 12px;
  padding-bottom: 12px;
}
.navigationHelpTable td, .navigationHelpTable th
{
  border: 1px solid #ddd;
  padding: 8px;
}
.navigationHelpTable  tr:nth-child(even)
{
  background-color: #f2f2f2;
}
.navigationHelpTable tr:hover 
{
  background-color: #ddd;
}

/////////////////////////////